<template>
  <div class="app login">
    <div class="login-in">
      <div class="view">
        <p class="title">Assistant Bot Web UI</p>
        <div class="container">
          <div class="inputs">
            <p class="tips" v-html="emailTips"></p>
            <div class="email input-indent">
              <input type="text" :placeholder="t('login.inputEmail')" maxlength="25" v-model="email" @focus="emailTips = '&nbsp;'" @keydown="keyDown($event)">
            </div>
            <p class="tips" v-html="passTips"></p>
            <div class="pass input-indent">
              <input type="password" :placeholder="t('login.inputPass')" maxlength="25" v-model="pass" @focus="passTips = '&nbsp;'" @keydown="keyDown($event)">
            </div>
            <p class="tips" v-html="captchaTips"></p>
            <div class="captcha input-indent">
              <input type="text" :placeholder="t('login.inputCaptcha')" maxlength="6" v-model="captchaCode" @focus="captchaTips = '&nbsp;'" @keydown="keyDown($event)">
            </div>
            <a href="javascript:void(0)" class="captcha-pic"><img :src="captchaRequest ? '/img/loading-captcha.svg' : captchaUrl" :alt="t('login.captchaFailed')" @click="captcha()"></a>
          </div>
          <p class="register">
            <span @click="register()">{{ t('login.register') }}</span>
            <i></i>
            <span @click="reset()">{{ t('login.update') }}</span>
          </p>
          <div class="submit" :style="{backgroundColor: logging ? 'rgba(96, 183, 255, 0.5)' : 'rgba(96, 183, 255, 1)', cursor: logging ? 'no-drop' : 'pointer'}" v-text="logging ? t('login.logging') : t('login.login')" @click="login()"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useDialog } from '../utils/dialog.js'
import { onMounted, reactive, toRefs } from 'vue'
import { captchaApi, loginApi } from '../api/index.js'

export default {
  name: 'Login',
  setup() {
    const { t } = useI18n()
    const Dialog = useDialog()
    const Router = useRouter()
    const Store = useStore()
    const data = reactive({
      // email: 'kurodaakira@163.com',
      // pass: 'Abcd1234',
      email: '',
      pass: '',
      captchaCode: '',
      captchaId: '',
      captchaUrl: '',

      emailTips: '&nbsp;',
      passTips: '&nbsp;',
      captchaTips: '&nbsp;',

      logging: false,
      captchaRequest: false
    })

    onMounted(() => {
      captcha()
    })

    // 获取验证码
    const captcha = () => {
      if (data.captchaRequest) return

      data.captchaRequest = true
      captchaApi().then(succ => {
        if (succ.code === 10000) {
          data.captchaId = succ.data.id
          data.captchaUrl = succ.data.pic
          data.captchaCode = ''
        } else if (succ.code === 10001) {
          Dialog({
            content: t('login.captchaApi.captchaOverTimes'),
            btns: [{ text: t('ok') }]
          })
        }
      }).finally(() => {
        data.captchaRequest = false
      })
    }

    // 登陆
    const login = () => {
      if (data.logging) return

      if (data.email.trim() === '') {
        data.emailTips = t('login.loginApi.emailCanNotEmpty')
        return
      }
      if (data.pass.trim() === '') {
        data.passTips = t('login.loginApi.passCanNotEmpty')
        return
      }
      if (data.captchaCode.trim() === '') {
        data.captchaTips = t('login.loginApi.captchaCanNotEmpty')
        return
      }

      data.logging = true
      loginApi({
        email: data.email,
        pass: data.pass,
        captchaId: data.captchaId,
        captchaCode: data.captchaCode
      }).then(succ => {
        if (succ.code < 10000) return

        if (succ.code !== 10000) {
          let errMsg = ''
          if (succ.code === 10001 || succ.code === 10003 || succ.code === 10004 || succ.code === 10005) {
            errMsg = t('login.loginApi.paramError')
          } else if (succ.code === 10002) {
            errMsg = t('login.loginApi.mailError')
            captcha()
          } else if (succ.code === 10006) {
            errMsg = t('login.loginApi.captchaError')
            captcha()
          } else if (succ.code === 10007) {
            errMsg = t('login.loginApi.loginFailedOverTimes')
          } else if (succ.code === 10009 || succ.code === 10011) {
            errMsg = t('login.loginApi.mailOrPassError')
            captcha()
          } else if (succ.code === 10010) {
            errMsg = t('login.loginApi.busy')
          }
          Dialog({
            content: errMsg,
            btns: [{ text: t('ok') }]
          })
          return
        }

        Store.commit('set', { userLanguage: succ.data.language })
        Store.commit('setPanelNav', {
          nickname: succ.data.nickname,
          userAvatar: succ.data.pic
        })
        Router.push({ name: 'Panel' })
      }).finally(() => {
        data.logging = false
      })
    }

    // 注册弹窗
    const register = () => {
      Dialog({
        content: t('login.win.register'),
        btns: [{ text: t('ok') }]
      })
    }

    // 重制弹窗
    const reset = () => {
      Dialog({
        content: t('login.win.update'),
        btns: [{ text: t('ok') }]
      })
    }

    // 回车登陆
    const keyDown = ev => {
      if (ev.which === 13) login()
    }

    return { ...toRefs(data), t, captcha, register, login, reset, keyDown }
  }
}
</script>

<style lang="scss" scoped>
.login {
  display: table;
  &-in {
    display: table-cell;
    vertical-align: middle;
    .view {
      width: 600px;
      margin: -8% auto 0;
      padding: 20px 20px 30px;
      background: #fff;
      border-radius: 10px;
      box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.2);
      .title {
        height: 1.2em;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .container {
        padding: 0 25px;
        .inputs {
          .tips {
            color: rgb(255, 75, 75);
            font-size: 12px;
            padding-top: 2px;
            line-height: 1.5em;
          }
          .email, .pass, .captcha {
            height: 40px;
            overflow: hidden;
            border-radius: 5px;
            position: relative;
            border: 1px solid #999;
          }
          .captcha {
            display: inline-block;
            width: 70%;
          }
          .captcha-pic {
            float: right;
            overflow: hidden;
            border-radius: 5px;
            border: 1px solid #999;
            background: rgb(150, 150, 150);
            img {
              color: #484848;
              width: 120px;
              height: 40px;
              text-indent: 1em;
              line-height: 40px;
            }
          }
        }
        .register {
          text-align: right;
          margin-top: 10px;
          span {
            cursor: pointer;
            border-bottom: 1px solid #222;
          }
          i {
            display: inline-block;
            width: 1em;
          }
        }
        .submit {
          color: #fff;
          width: 250px;
          cursor: pointer;
          line-height: 40px;
          text-align: center;
          margin: 20px auto 0;
          border-radius: 5px;
        }
      }
    }
  }
}
</style>
